import { useGraphQL, useInfiniteGraphQL } from '@/data';
import { sendReactNativeNotification } from '@/helpers/reactNativePostMessage';
import useColumnProps from '@/helpers/useColumnProps';
import useEventEffect from '@/hooks/useEventEffect';
import useHorizontalScrollShadow from '@/hooks/useScroll';
import { useEvents } from '@/providers/event';
import { useMenu } from '@/providers/menu';
import type { FilterOptions } from '@/types/schema';
import {
	CheckOutlined as CheckOutlinedIcon,
	ClearOutlined as ClearOutlinedIcon,
	ExpandMore as ExpandMoreIcon,
	InboxRounded as InboxRoundedIcon,
} from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Container,
	Divider,
	LinearProgress,
	ListItemButton,
	ListItemButtonProps,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Stack,
	Table,
	TableContainer,
	TableRowProps,
	Theme,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { format } from 'date-fns';
import type { DocumentNode } from 'graphql';
import { isArray, isEmpty, merge, omit, set } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import React, { isValidElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Column, ColumnInstance, TableOptions, TableState } from 'react-table';
import {
	useExpanded,
	useFilters,
	useFlexLayout,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table';
import { useWindowSize } from 'react-use';
import { Virtuoso } from 'react-virtuoso';
import { ActionProps, ActionPropsArray } from '../actions';
import CustomCheckBox from '../customCheckBox';
import Loading from '../loading';
import OverflowTypography from '../overflowTypography';
import ArrayFilter from './filters/arrayFilter';
import BooleanFilter from './filters/booleanFilter';
import DateFilter from './filters/dateFilter';
import ExistFilter from './filters/existFilter';
import NumberFilter from './filters/numberFilter';
import OptionsFilter from './filters/optionsFilter';
import StringFilter from './filters/stringFilter';
import GraphqlTableFooter from './footer';
import GraphqlTableHeader from './header';
import TableBody from './tableBody';
import TableFooter from './tableFooter';
import TableHeader from './tableheader';

const DEFAULT_QUERY_LIMIT = 10;
export const INFINITE_QUERY_LIMIT = 50;

interface ListItemMenuProps extends ListItemButtonProps {
	children: any,
	actions_: any,
	row: any,
	hasCheckbox?: boolean
};

export type ActionType = {
	items?: ActionPropsArray,
	max?: number
};

export type HeaderExtraActionsType = ActionType | ReactNode;

export type QuickActionsType = {
	name: string,
	onClick: () => void
};

type GraphqlTableProps<S, T> = {
	queryKey: string | string[],
	query: DocumentNode,
	variables?: T,
	subscription?: Record<string, string>,
	searchable?: boolean,
	tableActionMenu?: string[],
	tableSavedFilters?: string[],
	useQuickFilters?: () => QuickActionsType[],
	hiddenTableColumns?: string,
	columns: Array<Column<any> | null> | ( ( args?: any ) => Array<Column<any> | null> ),
	initialState?: Partial<TableState<{
		value: any
	}>>,
	rowRoute?: ( row: S ) => string,
	onRowClick?: ( row: S ) => void,
	expandedComponent?: ( row: S ) => ReactNode,
	disableUrlSync?: boolean,
	rowProps?: ( row: S ) => TableRowProps,
	showFooter?: boolean,
	useActions?: ( row: S ) => ActionProps[] | ActionType,
	renderDeletedData?: () => ReactNode,
	renderMultiActions?: ( rows: string[], clearRows: () => void, selectedRows?: S[] ) => ReactNode,
	mobileRenderRow?: ( row: S ) => ReactNode,
	defaultRenderScreen?: 'mobile' | 'desktop',
	refreshable?: boolean,
	hideColumnFilters?: boolean,
	returnNullOnEmpty?: boolean,
	isGroupedBy?: boolean,
	pageSizeOptions?: number[],
	headerExtraActions?: HeaderExtraActionsType,
	onPageChange?: ( pageIndex: number, pageSize: number ) => void,
	pageRowsSelectedOnLoad?: boolean,
	actionCellWidth?: number
};

export default function GraphqlTable<S extends object, T extends {
	search?: string,
	options?: FilterOptions | null
}>( {
	queryKey,
	query,
	columns     : initColumns,
	initialState: initState,
	searchable,
	tableActionMenu,
	variables: extraVariables,
	subscription,
	tableSavedFilters,
	useQuickFilters,
	hiddenTableColumns,
	expandedComponent,
	disableUrlSync,
	rowRoute,
	onRowClick,
	rowProps,
	showFooter,
	useActions,
	renderDeletedData,
	renderMultiActions,
	mobileRenderRow,
	defaultRenderScreen = 'desktop',
	refreshable,
	isGroupedBy,
	hideColumnFilters,
	returnNullOnEmpty = false,
	pageSizeOptions = [ 10, 25, 50, 100 ],
	headerExtraActions,
	onPageChange,
	pageRowsSelectedOnLoad,
	actionCellWidth = 160,
}: GraphqlTableProps<S, T> ) {
	const { height } = useWindowSize();
	const { t } = useTranslation();
	const router = useRouter();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const event = useEvents();
	const [ variables, setVariables ] = useState<{ options: FilterOptions }>();
	const [ filterUrl, setFilterUrl ] = useState( false );
	const [ firstFetching, setFirstFetching ] = useState( true );
	const [ selectedRows, setSelectedRows ] = useState( [] );
	const [ showMobileCheckbox, setShowMobileCheckbox ] = useState( false );
	
	const tableHeaderRef = useRef<HTMLDivElement>( null );
	const tableRef = useRef<HTMLDivElement>( null );
	const { handleScrollCheck } = useHorizontalScrollShadow( tableRef );
	
	const showCheckBox = Boolean( renderMultiActions );
	
	// TODO: should move the variables states and do all logic here:
	const queryVariables = useMemo( () => {
		const vars = merge( {}, variables, variables?.options?.orderBy
			? omit( extraVariables, [ 'options.orderBy' ] )
			: extraVariables );
		if ( !vars.options?.limit ) merge( vars, { options: { limit: DEFAULT_QUERY_LIMIT } } );
		return vars;
	}, [ variables, extraVariables ] );
	
	const { data, error, refetch, isFetching } = useGraphQL( {
			queryKey,
			query,
			variables: queryVariables,
			subscription,
		}, { keepPreviousData: true, enabled: Boolean( variables ) && !isMobile },
	);
	
	const infiniteQueryVariables = useMemo( () => {
		const vars = merge( {}, variables, variables?.options?.orderBy
			? omit( extraVariables, [ 'options.orderBy' ] )
			: extraVariables );
		merge( vars, { options: { limit: INFINITE_QUERY_LIMIT } } );
		return vars;
	}, [ variables, extraVariables ] );
	
	const {
		      flattenedData,
		      count: infiniteGqlTotalCount,
		      fetchNextPage,
		      hasNextPage,
		      isFetching: fetchingInfiniteGql,
	      } = useInfiniteGraphQL( {
		queryKey : Array.isArray( queryKey ) ? queryKey : [ queryKey ],
		query    : query,
		variables: infiniteQueryVariables,
	}, { keepPreviousData: true, enabled: isMobile } );
	
	useEventEffect( event, 'reload.graphqlQuery', async ( refetchQuery ) => {
			if ( refetchQuery ) await refetch();
		}, [],
	);
	
	useEventEffect( event, 'url.filter', ( urlFilter ) => {
			if ( urlFilter ) setFilterUrl( urlFilter );
		}, [],
	);
	
	const { items = [], count = 0 } = isMobile
		? { count: infiniteGqlTotalCount, items: flattenedData || [] }
		: !isEmpty( data )
			? Object.values( data )[ 0 ] as any || { items: [], count: 0 }
			: { items: [], count: 0 };
	
	const safeItems = items || [];
	const safeCount = typeof count === 'number' ? count : 0;
	
	const initialState = useMemo( () => {
		if ( disableUrlSync ) return initState || {};
		
		const state = router.query.s ? JSON.parse( atob( decodeURIComponent( router.query.s as string ) ) ) : undefined;
		
		return merge( { limit: pageSizeOptions[ 0 ] }, state, initState );
	}, [] );
	
	const columns = useMemo( () => ( typeof initColumns === 'function'
			? initColumns()
			: initColumns ).map( ( column ) => {
			let Filter, Cell;
			switch ( column.sortType ) {
				case 'number':
					Filter = NumberFilter;
					Cell = ( { value } ) => <OverflowTypography>{value}</OverflowTypography>;
					break;
				case 'datetime':
					Filter = DateFilter;
					Cell = ( { value } ) =>
						value ? <OverflowTypography>{format( new Date( value ), 'PPp' )}</OverflowTypography> : null;
					break;
				case 'basic':
					Filter = BooleanFilter;
					Cell = ( { value } ) => value ? <CheckOutlinedIcon/> : <ClearOutlinedIcon/>;
					break;
				case 'select':
					Filter = ArrayFilter;
					break;
				case 'options':
					Filter = OptionsFilter;
					break;
				case 'exist':
					Filter = ExistFilter;
					break;
				default:
					Filter = StringFilter;
					Cell = ( { value } ) => <OverflowTypography>{value}</OverflowTypography>;
					break;
			}
			const newVar = {
				Filter: column.disableFilters ? undefined : Filter,
				Cell,
				width : 100,
				...column,
			};
			if ( newVar.Filter === undefined ) delete newVar.Filter;
			if ( newVar.Cell === undefined ) delete newVar.Cell;
			return newVar;
		} ),
		[ isGroupedBy ],
	);
	
	const tableOptions = useMemo<TableOptions<any>>( () => ( {
			columns,
			data                  : safeItems,
			getRowId              : ( { id } ) => id,
			manualPagination      : true,
			manualGlobalFilter    : true,
			manualSortBy          : true,
			manualFilters         : true,
			autoResetPage         : false,
			autoResetExpanded     : false,
			autoResetFilters      : false,
			autoResetGlobalFilter : false,
			autoResetSortBy       : false,
			autoResetGroupBy      : false,
			autoResetSelectedRows : false,
			autoResetHiddenColumns: false,
			disableMultiSort      : false,
			pageCount             : Math.ceil( safeCount / ( variables?.options.limit ?? 1 ) ),
			initialState          : {
				...initialState,
				pageSize: pageSizeOptions[ 0 ],
			},
		} ), [ columns, safeItems, safeCount, variables, pageSizeOptions ],
	);
	const table = useTable(
		tableOptions,
		useGlobalFilter,
		useFilters,
		useSortBy,
		useExpanded,
		usePagination,
		useRowSelect,
		useFlexLayout,
		useColumnProps,
	);
	
	useEffect( () => {
		if ( disableUrlSync ) return;
		
		if ( filterUrl ) {
			const state = router.query.s
				? JSON.parse( atob( decodeURIComponent( router.query.s as string ) ) )
				: undefined;
			
			if ( !isEmpty( state?.filters ) ) {
				table.setAllFilters( state.filters );
			}
			setFilterUrl( false );
		}
	}, [ router.asPath ] );
	
	const filterObject = table.state.filters
		.filter( ( item ) => item.value.temp?.checked && Object.keys( item.value ).length > 1 )
		.reduce( ( obj, curr ) => {
			const temp: any = typeof curr.value === 'string' ? curr.value : omit( curr.value, [ 'temp' ] );
			const column: ColumnInstance<any> | undefined = table.columns.find( ( { id } ) => id === curr.id );
			if ( isArray( column?.filterByKey ) || isArray( curr.id ) ) {
				set( obj, '$or', temp?.$or );
			} else {
				set( obj, column?.filterByKey?.split( '.' )[ 0 ] || curr.id, temp );
			}
			return obj;
		}, {} );
	
	useEffect( () => {
		setVariables( ( prevVariables: any ) => {
			const newVariables = {
				...prevVariables,
				options: {
					...prevVariables?.options,
					aggregateItems: isGroupedBy,
					search        : table.state.globalFilter,
					limit         : isMobile ? INFINITE_QUERY_LIMIT : table.state.pageSize || DEFAULT_QUERY_LIMIT,
					offset        : isMobile ? undefined : table.state.pageIndex * table.state.pageSize,
					orderBy       : table.state.sortBy.length ? table.state.sortBy.map( ( order ) => {
						const column: ColumnInstance<any> = table.columns.find(
							( col ) => col.id === order.id || col?.filterByKey === order.id,
						);
						const filterByKey = isArray( column?.filterByKey ) ? column?.filterByKey?.[ 0 ] : column?.filterByKey;
						
						return `${filterByKey || column?.id}:${order.desc ? 'DESC' : 'ASC'}`;
					} ) : undefined,
					filter        : filterObject,
				},
			};
			if ( onPageChange ) {
				onPageChange( table.state.pageIndex, table.state.pageSize );
			}
			
			// Return new variables only if they have changed to avoid unnecessary re-renders
			return JSON.stringify( newVariables ) !== JSON.stringify( prevVariables ) ? newVariables : prevVariables;
		} );
	}, [
		isGroupedBy,
		table.state.globalFilter,
		table.state.pageIndex,
		table.state.pageSize,
		table.state.sortBy,
		filterObject,
	] );
	
	// if user search or filter, always take them to page one
	useEffect( () => {
		if ( table.state.globalFilter || !isEmpty( table.state.filters ) ) {
			table.state.pageIndex = 0;
		}
	}, [ table.state.globalFilter, table.state.filters ] );
	
	useEffect( () => {
		if ( !isFetching ) setFirstFetching( false );
	}, [ isFetching ] );
	
	useEffect( () => {
		if ( disableUrlSync ) return;
		
		const newQueryObj: any = {};
		
		const customSortBy = table.allColumns.reduce( ( arr, column ) => {
			if ( column.id === table.state.sortBy[ 0 ]?.id && column?.filterByKey?.length > 0 ) {
				arr = [ { id: column.filterByKey, desc: table.state.sortBy[ 0 ]?.desc } ];
			}
			return arr;
		}, [] );
		
		if ( table.state.pageIndex ) newQueryObj.pageIndex = table.state.pageIndex;
		if ( table.state.pageSize !== pageSizeOptions[ 0 ] ) newQueryObj.pageSize = table.state.pageSize;
		if ( table.state.sortBy.length ) newQueryObj.sortBy = !isEmpty( customSortBy )
			? customSortBy
			: table.state.sortBy;
		if ( table.state.globalFilter ) newQueryObj.globalFilter = table.state.globalFilter;
		
		if ( table.state.filters.length )
			newQueryObj.filters = table.state.filters.filter( ( item ) => Object.keys( item.value ).length > 1 );
		
		router.push( {
				pathname: router.asPath.split( '?' )[ 0 ],
				query   : {
					...router.query && !( 's' in router.query ) ? router.query : {},
					...Object.keys( newQueryObj ).length
						? { s: encodeURIComponent( btoa( JSON.stringify( newQueryObj ) ) ) }
						: {},
				},
			}, undefined,
			{ shallow: true },
		).then();
	}, [
		table.state.pageIndex,
		table.state.pageSize,
		table.state.sortBy,
		table.state.filters,
		table.state.globalFilter,
	] );
	
	const hasNoFiltersAndSearch = returnNullOnEmpty && isEmpty( safeItems ) && isEmpty( filterObject ) && !variables?.options?.search;
	
	if ( error ) {
		return (
			<Container maxWidth='md'>
				<Stack spacing={2} alignItems='center' justifyContent='center'>
					<Stack spacing={1} mt={3} textAlign='center'>
						<Typography variant='h4'>{t( 'common:error-info' )}</Typography>
						<Typography color='text.secondary'>{t( 'common:error-info-sub' )}</Typography>
						<Button color='primary' onClick={() => location.reload()}>
							{t( 'common:refresh' )}
						</Button>
					</Stack>
					<Accordion
						square
						sx={{
							'width'                     : '100%',
							'borderRadius'              : 2,
							'&.MuiAccordion-root:before': { display: 'none' },
						}}>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							<Typography variant='h6'>{t( 'common:error-details' )}</Typography>
						</AccordionSummary>
						<AccordionDetails sx={{ p: 2, mx: 3 }}>
							<Typography>{error?.response?.data?.error?.message || JSON.stringify( error, null, 2 )}</Typography>
						</AccordionDetails>
					</Accordion>
				</Stack>
			</Container>
		);
	}
	
	if ( hasNoFiltersAndSearch ) return (
		<Typography
			textAlign='center'
			color='text.secondary'
			py={1}>
			Nothing to display
		</Typography>
	);
	
	if ( mobileRenderRow && ( defaultRenderScreen === 'mobile' || isMobile ) ) {
		const selectedRowIds = Object.keys( table.state.selectedRowIds );
		
		return (
			<Box>
				<GraphqlTableHeader
					table={table}
					count={safeCount}
					headerExtraActions={headerExtraActions}
					tableSavedFilters={tableSavedFilters}
					useQuickFilters={useQuickFilters}
					hiddenTableColumns={hiddenTableColumns}
					tableActionMenu={tableActionMenu}
					variables={variables}
					badgeCount={Object.keys( filterObject ).length}
					searchable={searchable}
					renderMultiActions={renderMultiActions}
					refreshable={refreshable}
					setShowMobileCheckbox={setShowMobileCheckbox}
					showMobileCheckbox={showMobileCheckbox}
				/>
				{fetchingInfiniteGql && table.state?.globalFilter && ( <Loading/> )}
				<Virtuoso
					useWindowScroll
					style={{ height: height * 0.8 }}
					data={table.page}
					endReached={() => {
						if ( hasNextPage && !fetchingInfiniteGql ) {
							fetchNextPage();
						}
					}}
					itemContent={( index, row ) => {
						table.prepareRow( row );
						return (
							<Box onClick={() => showMobileCheckbox ? row.toggleRowSelected() : undefined}>
								<ListItemMenu
									key={index}
									actions_={useActions}
									hasCheckbox={showMobileCheckbox}
									row={row}>
									{showMobileCheckbox && (
										<CustomCheckBox
											sx={{ mr: 1 }}
											disabled={selectedRowIds.length >= 100 && !row.isSelected}
											{...row.getToggleRowSelectedProps()}
										/>
									)}
									{mobileRenderRow( row.original )}
								</ListItemMenu>
							</Box>
						);
					}}
					components={{ Footer: () => hasNextPage || fetchingInfiniteGql ? <Loading/> : null }}
				/>
			</Box>
		);
	}
	
	return (
		<Box pb={5}>
			<GraphqlTableHeader
				table={table}
				count={safeCount}
				headerExtraActions={headerExtraActions}
				tableSavedFilters={tableSavedFilters}
				useQuickFilters={useQuickFilters}
				hiddenTableColumns={hiddenTableColumns}
				tableActionMenu={tableActionMenu}
				variables={variables}
				badgeCount={Object.keys( filterObject ).length}
				searchable={searchable}
				renderMultiActions={renderMultiActions}
				refreshable={refreshable}
				hideColumnFilters={hideColumnFilters}
				selectedRows={selectedRows}
			/>
			<TableContainer
				ref={tableRef}
				sx={{
					border      : 1,
					borderRadius: 1,
					borderColor : 'divider',
					overflow    : 'auto',
				}}
				onScroll={handleScrollCheck}>
				{firstFetching
					? <Loading/>
					: (
						<Table
							size='small'
							component='div'
							sx={{
								'position'                                : 'relative',
								'.MuiChip-root'                           : { cursor: 'inherit' },
								'.MuiTableRow-root'                       : {
									bgcolor: 'background.default',
								},
								'.MuiTableCell-root'                      : {
									display        : 'flex', // breaks ellipsies
									whiteSpace     : 'nowrap',
									overflow       : 'hidden',
									alignItems     : 'center',
									textOverflow   : 'ellipsis',
									WebkitLineClamp: 1,
									borderTop      : 1,
									borderBottom   : 0,
									borderTopColor : 'divider',
								},
								'.MuiTableSortLabel-icon'                 : { opacity: 0.25 },
								'.MuiTableCell-head'                      : {
									bgcolor: ( theme ) => theme.palette.mode === 'dark'
										? '#37383A !important'
										: '#E0E0E0 !important',
									border : 0,
									color  : 'text.secondary',
								},
								'.MuiTableBody-root .MuiCollapse-root'    : {
									borderBottom: 0,
									borderColor : 'divider',
								},
								'.expandedCell'                           : {
									whiteSpace: 'normal',
									borderTop : 0,
									zIndex    : 1,
								},
								'.expandedCell p'                         : { color: 'colors.info.main' },
								'.MuiBadge-badge'                         : { bgcolor: 'red' },
								'.MuiTableRow-footer .MuiTypography-body1': { color: 'text.secondary' },
							}}
							{...table.getTableProps()}>
							<TableHeader
								table={table}
								tableHeaderRef={tableHeaderRef}
								showCheckBox={showCheckBox}
								expandedComponent={expandedComponent}
								useActions={useActions}
								setSelectedRows={setSelectedRows}
								selectedRows={selectedRows}
								actionCellWidth={actionCellWidth}
							/>
							{isFetching && (
								<LinearProgress
									sx={{
										position: 'absolute',
										top     : tableHeaderRef.current?.offsetHeight,
										height  : '2.5px',
										width   : '100%',
									}}
								/>
							)}
							{!isEmpty( table.page ) ? (
								<TableBody
									table={table}
									rowRoute={rowRoute}
									rowProps={rowProps}
									showCheckBox={showCheckBox}
									expandedComponent={expandedComponent}
									useActions={useActions}
									pageRowsSelectedOnLoad={pageRowsSelectedOnLoad}
									selectedRows={selectedRows}
									setSelectedRows={setSelectedRows}
									actionCellWidth={actionCellWidth}
									onRowClick={onRowClick}
								/>
							) : (
								<Stack direction='column' alignItems='center' justifyContent='center' p={5}>
									<InboxRoundedIcon sx={{ color: 'divider', fontSize: 40 }}/>
									<Typography color='text.secondary' textAlign='center'>
										{t( 'common:no-data' )}
									</Typography>
								</Stack>
							)}
							{showFooter && table.rows.length > 1 && (
								<TableFooter
									table={table}
									showCheckBox={showCheckBox}
									expandedComponent={expandedComponent}
									useActions={useActions}
									actionCellWidth={actionCellWidth}
								/>
							)}
						</Table>
					)}
			</TableContainer>
			<GraphqlTableFooter
				table={table}
				pageSizeOptions={pageSizeOptions}
				renderDeletedData={renderDeletedData}
			/>
		</Box>
	);
}

function ListItemMenu( { children, actions_, row, hasCheckbox, ...props }: ListItemMenuProps ) {
	const { showMenu } = useMenu();
	const { enqueueSnackbar } = useSnackbar();
	
	const actions = actions_?.( row.original )?.items || actions_?.( row.original );
	
	return (
		<ListItemButton
			divider
			disableGutters
			sx={{ borderRadius: 0 }}
			onClick={( e ) => {
				sendReactNativeNotification( { type: 'haptic', value: 'light' } );
				if ( hasCheckbox ) {
					return;
				}
				showMenu( ( { closeMenu } ) => (
					<MenuList sx={{ minWidth: { sm: 200 }, p: { xs: 1, sm: 0 } }}>
						{actions.filter( Boolean ).map( ( action, index ) =>
							isValidElement( action ) ? undefined : (
								<Box key={index}>
									<MenuItem
										dense
										disabled={action.disabled}
										sx={{
											'my'            : 0.5,
											'px'            : 1,
											':first-of-type': { mt: 0 },
											':last-of-type' : { mb: 0 },
											'borderRadius'  : 0,
											'whiteSpace'    : 'unset',
											':hover'        : { borderRadius: 1 },
										}}
										onClick={async ( e ) => {
											closeMenu();
											try {
												await action.onClick?.( e );
											} catch ( e ) {
												enqueueSnackbar(
													String(
														e?.response?.data?.error?.message || e?.response?.data || e?.message || e,
													),
													{ variant: 'error' },
												);
											}
										}}
										{...action.props}
										{...action.menuItemProps}>
										{action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
										<ListItemText
											primary={action.name}
											secondary={action.details}
											{...action.menuItemProps?.listItemTextProps}
										/>
									</MenuItem>
									{index !== actions.length - 1 && (
										<Divider
											variant='inset'
											component='li'
											sx={{
												marginTop   : '4px !important',
												marginBottom: '4px !important',
											}}
										/>
									)}
								</Box>
							),
						)}
					</MenuList>
				), e.currentTarget );
			}}
			{...props}>
			{children}
		</ListItemButton>
	);
}
