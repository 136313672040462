import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import type { ColumnInstance } from 'react-table';
import CustomCheckBox from '../../customCheckBox';

export default function FilterAccordion( {
	column,
	filter,
	setFilter,
	clearChecks,
	setClearChecks,
}: {
	column: ColumnInstance<any>,
	filter: { temp: { checked } },
	setFilter: ( filter: any ) => void,
	clearChecks: boolean,
	setClearChecks: ( check: boolean ) => void
} ) {
	const [ expanded, setExpanded ] = useState( filter?.temp?.checked ?? false );
	const { t } = useTranslation();
	
	useEffect( () => {
		if ( !clearChecks ) setExpanded( false );
		if ( expanded ) setClearChecks( true );
	}, [ clearChecks, expanded ] );
	
	if ( !column.canFilter ) return null;
	
	return (
		<Accordion
			square
			elevation={0}
			expanded={expanded}
			onChange={( e, expanded ) => {
				setFilter( {
					...filter,
					temp: { ...filter?.temp, checked: expanded },
				} );
				setExpanded( expanded );
			}}>
			<AccordionSummary
				sx={{ fontWeight: 500 }}
				expandIcon={(
					<CustomCheckBox
						checked={filter?.temp?.checked ?? false}
						onClick={( e ) => e.stopPropagation()}
						onChange={( e ) => {
							setFilter( {
								...filter,
								temp: { ...filter?.temp, checked: e.target.checked },
							} );
							setExpanded( e.target.checked );
						}}
					/>
				)}>
				<Typography>{t( column.render( 'Header' ).valueOf() as string )}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{column.render( 'Filter', {
					filter,
					setFilter,
					column,
				} )}
			</AccordionDetails>
		</Accordion>
	);
}
