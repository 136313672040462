import { getAuthToken } from '@/firebase/client';
import { GRAPHQL_URL } from '@/pages/api/urls';
import { getSessionCompanyId } from '@/utils/getCompanyId';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const authLink = setContext( async ( { operationName }, { headers, ...rest } ) => {
	const sessionCompanyId = getSessionCompanyId() || '';
	const idToken = await getAuthToken();
	return {
		uri    : `${GRAPHQL_URL}?${operationName}`,
		headers: typeof window === 'undefined'
			? { authorization: process.env.SECRET, ...headers }
			: { authorization: idToken, company: sessionCompanyId, ...headers },
		...rest,
		...operationName === 'UserRead' && { fetchOptions: { cache: 'no-cache' } },
	};
} );

const apiAuthLink = setContext( async ( operation, { headers, ...rest } ) => ( {
	uri    : `${GRAPHQL_URL}?${operation.operationName}`,
	headers: { authorization: process.env.SECRET, ...headers },
	...rest,
	fetchOptions: { cache: 'no-cache' },
} ) );

const httpLink = new HttpLink( { uri: GRAPHQL_URL } );

export const apolloClient = new ApolloClient( {
	link          : authLink.concat( httpLink ),
	ssrMode       : typeof window === 'undefined',
	cache         : new InMemoryCache( { addTypename: false } ),
	defaultOptions: { query: { fetchPolicy: 'no-cache' } },
} );

export const apiApolloClient = new ApolloClient( {
	link          : apiAuthLink.concat( httpLink ),
	ssrMode       : typeof window === 'undefined',
	cache         : new InMemoryCache( { addTypename: false } ),
	defaultOptions: { query: { fetchPolicy: 'no-cache' } },
} );
