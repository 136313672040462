import { INFINITE_QUERY_LIMIT } from '@/components/graphqlTable';
import { getAuthToken } from '@/firebase/client';
import { useCompanyId } from '@/providers/auth/useSessionStorageCompanyId';
import { useEvents } from '@/providers/event';
import type { Maybe, Mutation, Query } from '@/types/schema';
import { getSessionCompanyId } from '@/utils/getCompanyId';
import { DocumentNode, OperationVariables, QueryOptions } from '@apollo/client';
import type { MutationOptions } from '@apollo/client/core/watchQueryOptions';
import type { UseInfiniteQueryOptions, UseQueryOptions } from '@tanstack/react-query';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import stringify from 'fast-json-stable-stringify';
import { flatten, unionBy } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { apolloClient } from './apollo';
import parseDates from './parseDates';

interface PageData {
	count?: number,
	items?: any[]
}

export interface InfiniteQuery {
	data: Query,
	pageParam?: number
}

// TODO: Remove this hardcoded error message
export const TEMP_HARDCODED_ERROR = 'Something went wrong! Please try again or refresh your page';

function errorHandling( e: Error ): any {
	if ( e.message === 'Failed to fetch' ) return; // Do nothing as it's due to cancelled requests
	console.error( e );
	// TODO: this is for API use case, should use apiQueryGraphQL or apiMutateGraphql.
	if ( typeof window === 'undefined' ) throw e;
	
	// UI error handling
	if ( e.message.startsWith( 'UI:' ) ) throw new Error( e.message.replace( 'UI:', '' ) );
	// throw e;
	throw new Error( TEMP_HARDCODED_ERROR );
}

export const axiosClient = axios.create();
axiosClient.interceptors.request.use( async ( config ) => {
	if ( !config.headers ) config.headers = {};
	const companyId = getSessionCompanyId() || '';
	if ( !config.headers.company ) config.headers.company = companyId;
	if ( !config.headers.authorization ) config.headers.authorization = await getAuthToken();
	return config;
} );

export async function queryGraphQL<T extends OperationVariables, R = Query>( props: QueryOptions<T, R> ): Promise<R> {
	return apolloClient.query<R>( { ...props, fetchPolicy: 'no-cache' } )
		.then( ( { data } ) => parseDates( data ) )
		.catch( errorHandling );
}

export async function mutateGraphQL<T extends OperationVariables, R = Mutation>( props: MutationOptions<R, T> ) {
	return apolloClient.mutate<R, T>( props )
		.then( ( { data } ) => parseDates( data ) )
		.catch( errorHandling );
}

export function useGraphQL<T extends OperationVariables, R = Query>( {
	queryKey,
	query,
	variables,
	subscription,
	context,
}: {
	queryKey: string | Array<string | undefined>,
	query: DocumentNode,
	variables?: T,
	subscription?: Record<string, string>,
	context?: any
}, options?: Omit<UseQueryOptions<R>, 'initialData'> & { initialData?: () => undefined } ) {
	const { sessionCompanyId } = useCompanyId();
	const keys = ( Array.isArray( queryKey ) ? queryKey : [ queryKey ] ).filter( Boolean ) as string[];
	const queryResult = useQuery<R, any>(
		keys.concat( sessionCompanyId || [], stringify( query ), stringify( variables ) ),
		() =>
			queryGraphQL<T, R>( {
				query,
				variables,
				context,
			} ),
		// options,
		{ placeholderData: {} as any, ...options },
	);
	const appEvent = useEvents();
	
	useEffect( () => {
		if ( !subscription || options?.enabled === false ) return;
		const listener = () => queryResult.refetch();
		const events = [];
		for ( const [ name, id ] of Object.entries( subscription ) ) {
			if ( !id ) continue;
			const event = `${name}:${id}`;
			appEvent.on( event, listener );
			events.push( event );
		}
		return () => {
			events.forEach( ( event ) => {
				appEvent.off( event, listener );
			} );
		};
	}, [ options?.enabled ] );
	
	return queryResult;
	// return { ...queryResult, data: queryResult.data === undefined ? {} as any : queryResult.data as Query | undefined };
}

export function useInfiniteGraphQL<T extends { options?: Maybe<{ limit?: Maybe<number> }> }>( {
		queryKey,
		query,
		variables,
		subscription, // TODO missing cursor based subscription from the backend
		context,
	}: {
		queryKey: string | Array<string | undefined>,
		query: DocumentNode,
		variables?: T,
		subscription?: Record<string, string>,
		context?: any
	},
	options?: Omit<UseInfiniteQueryOptions<InfiniteQuery>, 'initialData'> & { initialData?: () => undefined } ) {
	const limit = variables?.options?.limit || INFINITE_QUERY_LIMIT;
	const { sessionCompanyId } = useCompanyId();
	const keys = ( Array.isArray( queryKey ) ? queryKey : [ queryKey ] ).filter( Boolean ) as string[];
	const queryResult = useInfiniteQuery<InfiniteQuery, any>(
		keys.concat( sessionCompanyId || [], stringify( query ), stringify( variables ) ),
		async ( { pageParam = 0 } ) => {
			
			const response = await queryGraphQL( {
				query,
				variables: {
					...variables,
					options: {
						...variables?.options,
						limit : limit,
						offset: pageParam,
					},
				},
				context,
			} );
			const { items } = Object.values( response )[ 0 ] as { items: any[] };
			
			return {
				data     : response,
				pageParam: items.length === limit ? pageParam + limit : undefined,
			};
		},
		{
			getNextPageParam: ( lastPage, allPages ) => {
				const currentPageOffset = lastPage.pageParam || 0;
				
				const firstPageData = allPages?.[ 0 ]?.data
					? Object.values( allPages[ 0 ]?.data )?.[ 0 ] as PageData
					: undefined;
				const total = firstPageData?.count || 0;
				
				const fetchedItemsCount = allPages.reduce( ( acc, page ) => {
					const pageData = Object.values( page.data )?.[ 0 ] as PageData;
					const itemsLength = pageData.items?.length || 0;
					return acc + itemsLength;
				}, 0 );
				
				if ( fetchedItemsCount < total ) {
					return currentPageOffset + limit;
				}
				return undefined;
			},
			...options,
		},
	);
	
	const firstPageData = queryResult?.data?.pages?.[ 0 ]?.data as PageData;
	const totalCount = Object.values( firstPageData || {} )?.[ 0 ]?.count || 0;
	
	const flattenedData: any = useMemo( () => {
		if ( !queryResult?.data?.pages ) return [];
		return unionBy( flatten( queryResult?.data?.pages?.map( ( { data } ) => Object.values( data as PageData || {} )?.[ 0 ]?.items ) ), 'id' );
	}, [ queryResult?.data?.pages ] );
	
	return {
		...queryResult,
		flattenedData,
		count: totalCount,
	};
}
